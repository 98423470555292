.UK_universities{
    background: linear-gradient(0deg, rgba(1, 33, 105, 1) 7%, rgba(255, 255, 255, 1) 99%);
    height: fit-content;
    z-index: 0;
    position: relative;
    padding-bottom: 3vh;
}

.UK_universities h1{
    margin-top: 0;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 3vw;
    color: rgba(177, 18, 18, 80%);
    text-shadow: 0 0 3px #ffffff;
}

.UK_uni_images {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    z-index: 1;
    padding-bottom:5vw;
}

.werty{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    margin:  5vw 10vw 0 10vw;
    width: 80%;
}

.UK_uni_images img{
    width: 18vw;
    height: 23vw;
    overflow: hidden;
    background-size: 100%;
    border-radius: 1vw;
    margin: 0px 2vw;
}

.UK_uni_name1,.UK_uni_name2,.UK_uni_name3,.UK_uni_name4,.UK_uni_name5,.UK_uni_name6,.UK_uni_name7,.UK_uni_name8,.UK_uni_name9{
    white-space: nowrap;
    width: 19vw;
    height: 2vw;
    position: absolute;
    margin-top: 22vw;
    margin-left: 10vw;
    font-size: 1.7vw;
    border-radius: 20px;
    padding: 0.25vw 1.5vw ;
}

.UK_uni_name1{
    background-color: rgba(177, 18, 18, 80%);
    color:white;

}

.UK_uni_name2{
    background:#012169;  
    color:white;
  
}

.UK_uni_name3{
    background-color: rgba(177, 18, 18, 80%);
    color:white;
}

.UK_uni_name4{
    background:#ffffff;   
    color: black; 
}


.UK_uni_name5{
    background:#012169;  
    color:white;

}

.UK_uni_name6{
    background-color: rgba(177, 18, 18, 80%);
    color:white;
}

.UK_uni_name7{
    background:#012169;    
    color:white;
}

.UK_uni_name8{
    background-color: rgba(0, 0, 0, 50%);
    color:white;
}

.UK_uni_name9{
    background-color: rgba(177, 18, 18, 80%);
    color:white;
}



@media only screen and (min-width:550px) and (max-width:800px) {

    .UK_uni_images img{
        width: 25vw;
        height: 30vw;        
    }

    .UK_uni_name1,.UK_uni_name2,.UK_uni_name3,.UK_uni_name4,.UK_uni_name5,.UK_uni_name6,.UK_uni_name7,.UK_uni_name8,.UK_uni_name9{
        margin-top: 29vw;
        font-size: 2.3vw;
        width: 27vw;
        height: 4vh;
        margin-left: 15vw;
    }

    .UK_universities h1{
        font-size: 2rem;
    }
}

@media only screen and (min-width:0px) and (max-width:550px) {
    
    .UK_universities h1{
        font-size: 1.8rem;
        padding-top: 2vh;
    }

    .UK_uni_images{
        padding-bottom: 10vw;
    }

    .UK_uni_images img{
        width: 30vh;
        height: 35vh;  
    }

    .UK_uni_name1,.UK_uni_name2,.UK_uni_name3,.UK_uni_name4,.UK_uni_name5,.UK_uni_name6,.UK_uni_name7,.UK_uni_name8,.UK_uni_name9{
        margin-top: 34vh;
        margin-left: 22vw;
        font-size: 1.2rem;
        width: 37vh;
        height: 4vh;
    }

}