.Swe_universities{
    background: linear-gradient(180deg, rgb(227, 244, 241) 0%, rgba(12, 84, 72, 1) 100%);;
    height: fit-content;
    z-index: 0;
    position: relative;
    padding-bottom: 3vh;
}

.Swe_universities h1{
    margin-top: 0;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 3vw;
    color: #dddb6a;
    text-shadow: 0 0 5px #000000;
}

.Swe_uni_images {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    z-index: 1;
    padding-bottom:5vw;
}

.werty{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    margin:  5vw 10vw 0 10vw;
    width: 80%;
}

.Swe_uni_images img{
    width: 18vw;
    height: 23vw;
    overflow: hidden;
    background-size: 100%;
    border-radius: 1vw;
    margin: 0px 2vw;
}

.Swe_uni_name1,.Swe_uni_name2,.Swe_uni_name3,.Swe_uni_name4,.Swe_uni_name5,.Swe_uni_name6,.Swe_uni_name7,.Swe_uni_name8,.Swe_uni_name9{
    white-space: nowrap;
    width: 18vw;
    height: 3vh;    
    color:white;
    position: absolute;
    margin-top: 22vw;
    margin-left: 12vw;
    font-size: 16px;
    border-radius: 20px;
    padding:  0.5% 2%;
}

.Swe_uni_name1{
    background-color: rgba(12,47, 108,80%);
}

.Swe_uni_name2{
    background-color: rgba(255, 255, 0, 0.726);
    color: black;
}

.Swe_uni_name3{
    background-color: rgba(12,47, 108, 80%);
}

.Swe_uni_name4{
    background-color: rgba(255, 255, 0, 0.726);
    color: black;}

.Swe_uni_name5{
    background-color: rgba(12,47, 108,80%);
}

.Swe_uni_name6{
    background-color: rgba(255, 255, 0, 0.726);
    color: black;}

.Swe_uni_name7{
    background-color: rgba(12,47, 108,80%);

}
.Swe_uni_name8{
    background-color: rgba(255, 255, 0, 0.726);
    color: black;}

.Swe_uni_name9{
    background-color: rgba(12,47, 108,80%);
}

@media only screen and (min-width:550px) and (max-width:800px) {

    .Swe_uni_images img{
        width: 25vw;
        height: 30vw;        
    }

    .Swe_uni_name1,.Swe_uni_name2,.Swe_uni_name3,.Swe_uni_name4,.Swe_uni_name5,.Swe_uni_name6,.Swe_uni_name7,.Swe_uni_name8,.Swe_uni_name9{
        margin-top: 29vw;
        font-size: 16px;
        width: 30vw;
        height: 3vh;
        margin-left: 15vw;
    }

    .pop_universities h1{
        font-size: 2rem;
    }
}

@media only screen and (min-width:0px) and (max-width:550px) {
    .Swe_universities{
        justify-content: center;
        align-items: center;
        align-self: center;
    }

    .werty{
        width: 80%;
        margin: 3vw auto;

    }


    .pop_universities h1{
        font-size: 2rem;
    }

    .Swe_uni_images{
        padding-bottom: 10vw;      
    }

    .Swe_uni_images img{
        width: 40vh;
        height: 50vh;  
    }

    .Swe_uni_name1,.Swe_uni_name2,.Swe_uni_name3,.Swe_uni_name4,.Swe_uni_name5,.Swe_uni_name6,.Swe_uni_name7,.Swe_uni_name8,.Swe_uni_name9{
        margin-top: 48vh;
        margin-left: 18vw;
        font-size: 16px;
        width: 35vh;
        height: 3vh;
    }

}