.services-container {
  padding: 30px;
  background-color: #00675B; 
  color: #E6A543;

}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  margin-top: 5px;
  color: #E6A543;
}

.services-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap:6%;
}


.service-image {
  width: 232px;
  height: 232px;
  margin-top: 3px;
  object-fit: cover;
  border-radius: 50%;
  /* filter: blur(1px); */
  position: relative;
  box-sizing: border-box;
  /* box-shadow: inset 0 0 0 2px red; */
}

.services-button {
background: none;
border: 2px;
/* box-sizing: border-box; */
/* margin: 1em; */
/* padding: 1em 1em; */
/* box-shadow: inset 0 0 0 2px red; */
color: red;
font-size: inherit;
font-weight: 700;
/* position: relative; */
/* position: absolute; */
/* vertical-align: middle; */

&::before,
&::after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
}
}

.spin {
  width: 15em;
  height: 15em;
  padding: 0;

  &:hover {
    color: #b59b0c;
  }

  &::before,
  &::after {
    top: 0;
    left: 0;
  }

  &::before {
    border: 4px solid transparent; 
  }

  &:hover::before {
    border-top-color: yellow; 
    border-right-color: yellow;
    border-bottom-color: yellow;
    border-left-color: yellow;

    transition:
      border-left-color 0.20s linear 0.20s,
      border-top-color 0.20s linear 0.30s, 
      border-right-color 0.20s linear 0.40s,
      border-bottom-color 0.20s linear 0.50s;
  }

  &::after {
    border: 2 solid transparent; 
  }

  &:hover::after {
    /* border-top: 2px solid #b59b0c;  */
    /* border-left-width: 2px;  */
    /* border-right-width: 2px;  */
    transform: rotate(360deg);
    transition:
      /* transform 0.4s linear 0s, */
      /* border-left-width 0s linear 0.30s;  */
  }
}

.circle {
/* border-radius: 20%; */
box-shadow: none;
  
&::before,
&::after {
  border-radius: 100%;
}
}

.service-title {
  position: absolute;
  bottom: 40px;
  font-size: 20px;
  font-weight: bold;
  text-shadow: rgb(0, 0, 0) 1px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px;
  text-align: center;
  width: 70%;
  color: yellow;
}

/* Base styling for service item */
.service-item {
  position: relative;
  /* width: 18%;
  height: 18%; */
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}


@media only screen and (min-width: 768px) and (max-width:1024px){
  .spin{
    width:12em;
    height:12em;
  }
  /* .service-item{
    width: 200px;
    height: 180px;
  } */

  .service-image {
    width: 185.2px;
    height: 185.2px;
    margin-top: 3px;
    object-fit: cover;
    border-radius: 50%;
    /* filter: blur(1px); */
    position: relative;
    box-sizing: border-box;
    /* box-shadow: inset 0 0 0 2px red; */
  
  }

  .services-grid{
    gap:3%;
  }

  .service-title{
    font-size: 18px;
    width: 80%;
  }
}

@media only screen and (min-width: 650px) and (max-width:768px){
  h2{
    font-size: 1.5rem;
  }
  .service-item{
    margin-bottom: 3%;
  }

  .service-image {
    width: 153px;
    height: 153px;
    margin-top: 3px;
    object-fit: cover;
    border-radius: 50%;
    /* filter: blur(1px); */
    position: relative;
    box-sizing: border-box;
    /* box-shadow: inset 0 0 0 2px red; */
  }

  .services-grid{
    gap: 5%;
  }

  .service-title {
    position: absolute;
    bottom: 18%;
    left: 8%;
    font-size: 1rem;
    text-align: center;
    width: 80%;
  }
  .spin{
    width:10em;
    height:10em;
  }
}

@media only screen and (min-width: 0px) and (max-width:650px){
  h1{
   text-align: center;
  }

  .spin{
    width:13em;
    height:13em;
  }

  .service-item{
    margin-bottom: 3%;
  }

  .service-image {
    width: 200px;
    height: 200px;
    margin-top: 3.5px;
    object-fit: cover;
    border-radius: 50%;
    /* filter: blur(1px); */
    position: relative;
    box-sizing: border-box;
    /* box-shadow: inset 0 0 0 2px red; */
  }

  .service-title {
    position: absolute;
    bottom: 16%;
    left: 9%;
    font-size: 1.1rem;
    text-align: center;
    width: 80%;
  }

}

