
.news-section {
    padding: 50px 20px;
    text-align: left;
  }
  
  .news-heading {
    font-size: 45px;
    color: #0c5448;
    margin-bottom: 10px;
    margin-left: 30px;
  }
  
  .news-subheading {
    font-size: 20px;
    color: #4a4a4a;
    margin-bottom: 40px;
    margin-left: 30px;
  }
  
  .news-cards {
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
  }
  
  .news-card {
    background-color: white;
    height: 360px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 400px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .news-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .news-image {
    width: 100%;
    height: 30vh;
    object-fit: cover;
  }
  

  .news-content {
    padding: 20px 20px 40px 20px;
  }
  
  .news-date {
    font-size: 16px;
    color: #888;
    margin-bottom: 10px;
  }
  
  .news-title {
    font-size: 20px;
    color: #0c5448;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 1000;
  }
  

  @media screen and (max-width: 1200px) {
    
  .news-card {
    height: 360px;
    width: 320px;
  }
    
  }

  @media screen and (max-width:1070px) {
    .news-card {
      height: 360px;
      width: 300px;
    }
  }

  @media (max-width: 770px) {
    .news-cards {
      flex-direction: column;
      align-items: center;
    }
  
    .news-card {
      width: 90%;
      height: 55vh;
      margin-bottom: 20px;
    }
    .news-heading {
      font-size: 35px;
    }
    
    .news-subheading {
      font-size: 14px;
    }
    
  }

  @media (min-width: 500px) and (max-width: 870px) {

    .news-card {
      width: 70%;
      height: 65vh;
      margin-bottom: 20px;
    }

    .news-image {
        width: 100%;
        height: 40vh;
        object-fit: cover;
      }
  }
  