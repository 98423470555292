
.explore-heading h1 {
  margin: 2%;
  font-size: 3rem;
  color: #0C5448;
  padding-bottom:2%;
}

.product-carousel {
  position: relative;
  overflow: hidden;
  padding: 26px 26px 26px 0;
}

.product-container {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: 100%; /* Ensures full width usage */
  max-width: 1400px; /* Adjust this based on design */
}

.pre-btn,
.next-btn {
  border: none;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  z-index: 5;
  margin: 0 10px;
}

.next-btn {
  right: 0;
}

.next-btn p,
.pre-btn p {
  font-size: 40px; /* Adjusted size for better aesthetics */
  background: white; /* Subtle gradient for a modern look */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Softer shadow for a clean effect */
  border-radius: 20%; /* Fully rounded button */
  color: rgb(50, 50, 50); /* Softer black for text */
  width: 60px; /* Slightly increased size for visual balance */
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transitions */
  padding: 5px;
}

.next-btn p:hover,
.pre-btn p:hover {
  background: #04b1e6; /* Subtle hover color change */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4); /* Increase shadow on hover */
  transform: scale(1.1); /* Slight scaling effect on hover */
}

.next-btn p:active,
.pre-btn p:active {
  transform: scale(0.95); /* Give it a pressed-down effect when clicked */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3); /* Reduce shadow to simulate pressing */
}

@media screen and (max-width: 800px){
  .product-container{
    margin-left: 20px;
  }
    
  .explore-heading h1{
    font-size: 2rem;
 }
}

@media screen and (max-width:500px) {
  .product-container{
    margin-left: 25px;
  }

  .explore-heading h1{
    text-align: center;
  }

  .next-btn p,
.pre-btn p {
  font-size: 25px; /* Adjusted size for better aesthetics */
  background: white; /* Subtle gradient for a modern look */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3); /* Softer shadow for a clean effect */
  border-radius: 50%; /* Fully rounded button */
  /* color: rgb(50, 50, 50); Softer black for text */
  width: 30px; /* Slightly increased size for visual balance */
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: all 0.3s ease; Smooth transitions */
  padding: 4px;
}
}

