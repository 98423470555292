.mission-section {
  display: flex;
  align-items: center;
  justify-content: center; /* Centers content horizontally */
  background: linear-gradient(to bottom, rgba(0, 100, 80, 0.9), rgba(0, 40, 30, 0.8));
}


.mission-section img {
  width: 90%;
  flex: 0.4;
}

.text-container {
  margin-left: 5vw;
  flex: 0.6;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.text-container h2 {
  font-size: 3rem; 
  font-family: 'Amiri', serif;
  font-weight: bold;
  color: #EFBF04;
  margin-bottom: 2.5vw;
}

.text-container p {
  font-size: 20px;
  line-height: 1.6;
  font-family: 'Amiri', serif;
  /* text-shadow: 4px 2px 3px rgba(0, 0, 0, 0.6); */
  margin: 0;
  width: 50vw;
}

/* For responsiveness */
@media (max-width: 1200px) {
  .text-container h2 {
    font-size: 2.5rem;
  }
  .text-container p {
    font-size: 18px;
  }
}

@media (max-width: 1000px) {
  .text-container h2 {
    font-size: 2.5rem;
  }
  .text-container p {
    font-size: 16px;
  }
}

@media (max-width: 760px) {
  .mission-section {
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    height: auto; /* Adjust height for smaller screens */
    /* padding: 10px; */
    }

  .mission-section img {
    width: 50%;
  }

  .text-container {
    width: 90%;
    margin: 0 ;
    align-items: center;
  }

  .text-container h2{
    margin:10px auto;
  }
  
  .text-container p{
    font-size: 16px;
    width: 90%;
    margin: 0 0 10px 0;    
  }
  
}

@media (max-width: 470px) {

  .mission-section {
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
    height: auto; /* Adjust height for smaller screens */
    }
  
  .mission-section img {
    height: 70vh;
    width: 100%;
  }

  .text-container {
    width: 90%;
    margin: 0 20px;
    padding:5px;
  }

  .text-container h2 {
    font-size: 32px;
    padding: 3vw;
    margin: 0px;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    text-align: center; /* Optional: aligns the text centrally within its container */
  }
  
  .text-container p {
    font-size: 16px;
    line-height: 1.3;
    width: 100%;
  }
}
