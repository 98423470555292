.Swe-why-rel-country-container{
    background: #0C5448;
    display: flex;
    height: 50vw;
    align-items: center;
    justify-content:center;
}

.Swe-why-rel-country-container-flag{
    padding: 2% 2% 2% 2%;
    z-index: 0;
    
}

.Swe-why-rel-country-container-flag img{
    height: 35vw;
    width: 35vw;
    border-radius: 50%;
    border: 2px solid #F3AE27;   
}

.Swe-rel-text{
    padding-left: 3%;
    width: fit-content;
}


.Swe-rel-text p{
    font-size: 1.1vw;
    width: 31vw; 
    color: white;
    margin: 1.3% auto;
    padding: 0 2%;
    line-height: 1.5;
    text-align: left;
}

.Swe-child1,.Swe-child2,.Swe-child3,.Swe-child4,.Swe-child5 {
    position: relative;
    border-radius: 50px;
    height:  5vw;
    margin: 1vw;
    width: 32vw;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.Swe-child1 {
    left: -2.5vw;
    background: linear-gradient(90deg, rgba(0, 106, 167, 1) 49%, rgba(254, 204, 2, 1) 100%);   
}

.Swe-child1 p {
    color: black !important;
}

.Swe-child2 {
    background: #006AA7;    
    left: -1vw;
}

.Swe-child2 p {
    color: white !important;
}

.Swe-child3 {
    background: #FECC02;
    left: 0;
}

.Swe-child3 p {
    color: #000 !important;
}

.Swe-child4 {
    background: #006AA7;
    left: -1vw;
}

.Swe-child5 {
    left: -2.5vw;
    background: linear-gradient(90deg, rgba(254, 204, 2, 1) 0%, rgba(0, 106, 167, 1) 51%);

}

.Swe-child5 p {
    color: black !important;
}


@media only screen and (max-width: 1000px){
    .Swe-child1,.Swe-child2,.Swe-child3,.Swe-child4,.Swe-child5 {
        height: 7vw;
        width: 45vw;
    }

    .Swe-rel-text p{
        font-size: 14px;
        margin:5px 10px;
        width: 41vw;
        line-height: 1.2;
        padding: 1%;
    }
}

@media only screen and (min-width:590px) and (max-width: 700px) {
    .Swe-why-rel-country-container{
        flex-direction: column;
        height: fit-content;
    }

    .Swe-child1,.Swe-child2,.Swe-child3,.Swe-child4,.Swe-child5 {
        height: 7vh;
        width: 60vw;
        margin: 1.5vw;
        left: 0;
    }

    .Swe-rel-text p{
        margin: 5px 10px;
        font-size: 0.7rem;
        width: 45vw;
        line-height: 1;
    }



}

@media only screen and (max-width:590px) {
    .Swe-why-rel-country-container{
        flex-direction: column;
        height: fit-content;
    }

    .Swe-why-rel-country-container-flag{
        margin-top: 20px;
    }

    .Swe-why-rel-country-container-flag img{
        height: 55vw;
        width: 55vw;
    }

    .Swe-child1,.Swe-child2,.Swe-child3,.Swe-child4,.Swe-child5 {
        width: 75vw;
        height: 12vw;
        margin: 3vw;
        left: 0;
    }

    .Swe-rel-text p{
        width: 70vw;
        font-size: 16px;
        padding: 1.2vw;
        margin-left: 10px;
    }   
}

@media screen and (max-width:500px) {
    .Swe-child1,.Swe-child2,.Swe-child3,.Swe-child4,.Swe-child5 {
        height: 10vh;
        width: 85vw;
    }
    .Swe-why-rel-country-container-flag img{
        width: 60vw;
        height: 60vw;
    }

    .Swe-child1,.Swe-child2,.Swe-child3,.Swe-child4,.Swe-child5 {
        width: 90vw;
        height: fit-content;
        margin: 3vw;
        left: 0;
    }

    .Swe-rel-text p{
        width: 85vw;
        font-size: 16px;
        padding: 2.5vw;
        margin-left: 10px;
    }
}