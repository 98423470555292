.Ger-why-rel-country-container{
    background: linear-gradient(1deg, rgba(0, 0, 0, 1) 0%, rgba(81, 74, 31, 1) 80%);
    display: flex;
    height: 50vw;
    align-items: center;
    justify-content:center;
}

.Ger-why-rel-country-container-flag{
    padding: 2% 2% 2% 2%;
    z-index: 0;
    
}

.Ger-why-rel-country-container-flag img{
    height: 35vw;
    width: 35vw;
    border-radius: 50%;
    border: 2px solid #F3AE27;   
}

.rel-text-Ger{
    padding-left: 3vw;
    width: 35vw;
}


.rel-text-Ger p{
    font-size: 1.1vw;
    width: 30vw; 
    color: white;
    margin: 1vw 0;
    padding: 0 4%;
}

.Ger-child1,.Ger-child2,.Ger-child3,.Ger-child4,.Ger-child5 {
    position: relative;
    border-radius: 50px;
    height:  5vw;
    margin: 1vw;
    width: 35vw;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.Ger-child1 {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(180, 50, 41, 1) 50%, rgba(255, 233, 36, 1) 100%);    
    left: -4vw;   
}

.Ger-child2 {
    background: black;    
    left: -2.5vw;
}

.Ger-child3 {
    background: #b11212;
    left: -1vw;
}

.Ger-child4 {
    background: #EFBF04;;
    left: -2.5vw;
}

.Ger-child5 {
    background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(180, 50, 41, 1) 50%, rgba(255, 233, 36, 1) 100%);    
    left: -4vw;
}

@media only screen and (max-width: 1000px){
    .rel-text-Ger{
        width: 40vw;
    }

    .Ger-child1,.Ger-child2,.Ger-child3,.Ger-child4,.Ger-child5 {
        height: 6vw;
        width: 36vw;
    }

    .rel-text-Ger p{
        font-size: 0.8rem;
        width: 35vw;
    }
}

@media only screen and (max-width:700px) {
    .Ger-why-rel-country-container{
        flex-direction: column;
        height: fit-content;
    }

    .Ger-why-rel-country-container-flag{
        margin-top: 20px;
    }

    .Ger-why-rel-country-container-flag img{
        height: 55vw;
        width: 55vw;
    }

    .Ger-child1,.Ger-child2,.Ger-child3,.Ger-child4,.Ger-child5 {
        width: 80vw;
        height: 10vh;
        margin: 3vw;
        left: 0;
        padding: 0;
    }

    .rel-text-Ger{
        width: 90%;
    }

    .rel-text-Ger p{
        width: 90%;
        font-size: 16px;
        padding: 1.2vw;
        margin-left: 10px;
    }   
}

@media screen and (max-width:500px) {
    .Ger-child1,.Ger-child2,.Ger-child3,.Ger-child4,.Ger-child5 {
        height: 10vh;
        width: 85vw;
    }
    .Ger-why-rel-country-container-flag img{
        width: 60vw;
        height: 60vw;
    }

    .Ger-child1,.Ger-child2,.Ger-child3,.Ger-child4,.Ger-child5 {
        width: 80vw;
        height: fit-content;
        margin: 3vw;
        left: 0;
    }

    .rel-text-Ger p{
        width: 70vw;
        font-size: 16px;
        padding: 2.5vw;
        margin-left: 10px;
    }
}