.sb-footer-section-padding{
    padding: 2rem 2rem;
}
.footer{
  
background: #0F594D;
background: linear-gradient(0deg, #0F594D, #000000);
}

.sb-footer{
    display: flex;
    flex-direction: column;
    
}
.sb-footer-links{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}
.sb-footer-links-div{
    padding: 1%;
    max-width: 200px;
    min-width: 100px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}
.sb-footer-section-padding .f_anchor{
    cursor: default;
    color:white;
    text-decoration: none;
    font-size: 0.82rem;
}
.socialmedia{
    display: flex;
    flex-direction: row;
}

.socialmedia img{
    width: 80%;
}

.sb-footer-links-div h4{
    font-size: 1.5rem;
    /* line-height: 17px; */
    margin-bottom: 0.9rem;
    overflow: hidden;
    width: fit-content;
    color:#BBCA18;
    
}
.sb-footer-links-div p{
    font-size: 14px;
    line-height: 15px;
    margin: 0.9rem 0;
    cursor: pointer;
    overflow: hidden;
}
 p.address{
    line-height:1.25rem;
}
.sb-footer-below{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}
.sb-footer-below-links{
    display: flex;
    flex-direction: row;
}
.sb-footer-below-links p{
    font-size: 13px;
    line-height: 15px;
    margin-left: 2rem;
    color: white;
    font-weight: 600;
}
.hr{
    color: white !important;
    width: 100%;
}
.sb-footer-copyright p{
    font-size: 13px;
    line-height: 15px;
    color: white;
    font-weight: 600;
}
.Parent_company {
    /* white-space: nowrap; */
    margin-top: 10px;
    display: flex;
    align-items: center; /* Vertically centers the content */
    height: 100%; /* Make sure the parent has a defined height */
}

.pc_name {
    color: rgb(220, 178, 73); /* Adjust color based on your background */
    width: 60%;
    font-size: 20px;
}

.Parent_company img {
    width: 100px; /* Set the size of the image */
    height: 100px;
}

.email-link{
    color: #ffffff;
}

@media screen and (max-width: 1250px){
    .sb-footer-section-padding{
        padding: 2rem 10px;
    }

    .sb-footer-links-div{
        width: fit-content;
        margin: 1rem 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        color: white;
    }
}

@media screen and (max-width: 768px){
    .sb-footer-section-padding{
        padding: 2rem 2rem;
    }

    .sb-footer-heading h1{
        font-size: 44px;
        line-height: 50px;
    } 
    .sb-footer-heading h1{
        font-size: 34px;
        line-height: 42px;
    }
    .sb-footer-links div{
        margin: 1rem 0;
    }
    .sb-footer-btn p{
        font-size: 14px;
        line-height: 20px;
    }
    .sb-footer-below{
        flex-direction: column;
        justify-content: left;
    }
    .sb-footer-below-links{
        flex-direction: column;
    }
    .sb-footer-below-links p{
        margin-left: 0rem;
        margin-top: 1rem;
    }
}

@media screen and (max-width: 400px){
    .sb-footer-heading h1{
        font-size: 27px;
        line-height: 38px;
    }
}

@media screen  and (max-width: 460px){

    .sb-footer-links-div:nth-of-type(1),.sb-footer-links-div:nth-of-type(2){
        display: none;
    }

    .sb-footer-links{
       width:100%;
       margin: 0;
       align-items: flex-start;
       padding: 0;
       justify-self: start;
       justify-content: center;
    } 
    .sb-footer-links-div{
        max-width: 300px;
        min-width: 300px ;
        padding: 0;
        margin: 0 0;
        justify-content: flex-start;
    }

    .pc_name {
        color: rgb(220, 178, 73); /* Adjust color based on your background */
        width: 60%;
        font-size: 30px;
    }
    
    .Parent_company img {
        width: 100px; /* Set the size of the image */
        height: 100px;
    }
    
}