.Fin_universities{
    background: linear-gradient(359deg, rgba(33, 150, 243, 1) 0%, rgba(255, 255, 255, 1) 100%);
    height: fit-content;
    z-index: 0;
    position: relative;
    padding-bottom: 3vh;
}

.Fin_universities h1{
    margin-top: 0;
    font-size: 2.5rem;
    text-align: center;
    padding-top: 3vw;
    color: #0d62d1;
}

.Fin_uni_images {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    z-index: 1;
    padding-bottom:5vw;
}

.werty{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    margin:  5vw 10vw 0 10vw;
    width: 80%;
}

.Fin_uni_images img{
    width: 18vw;
    height: 23vw;
    overflow: hidden;
    background-size: 100%;
    border-radius: 1vw;
    margin: 0px 2vw;
}

.Fin_uni_name1,.Fin_uni_name2,.Fin_uni_name3,.Fin_uni_name4,.Fin_uni_name5,.Fin_uni_name6,.Fin_uni_name7{
    white-space: nowrap;
    width: 20vw;
    height: 3vh;
    color:white;
    position: absolute;
    margin-top: 22vw;
    margin-left: 10vw;
    font-size: 17px;
    border-radius: 20px;
    padding: 5px 10px;
}

.Fin_uni_name1{
    background-color: rgba(12,47, 108,80%);
}


.Fin_uni_name2{
    background-color: white;
    color: black;
}

.Fin_uni_name3{
    background-color: rgba(12,47, 108, 80%);
}

.Fin_uni_name4{
    background-color: white;
    color: black;}

.Fin_uni_name5{
    background-color: rgba(12,47, 108,80%);
}

.Fin_uni_name6{
    background-color: white;
    color: black;}

.Fin_uni_name7{
    background-color: rgba(12,47, 108,80%);
}

@media only screen and (min-width:550px) and (max-width:800px) {

    .Fin_uni_images img{
        width: 25vw;
        height: 30vw;        
    }

    .Fin_uni_name1,.Fin_uni_name2,.Fin_uni_name3,.Fin_uni_name4,.Fin_uni_name5,.Fin_uni_name6,.Fin_uni_name7{
        margin-top: 29vw;
        font-size: 16px;
        width: 32vw;
        height: 3vh;
        margin-left: 20vw;
    }

    .pop_universities h1{
        font-size: 2rem;
    }
}

@media only screen and (min-width:0px) and (max-width:550px) {
    
    .Fin_universities h1{
        font-size: 1rem;
        padding-top: 2vh;
    }
    .Fin_uni_images {
        padding-bottom: 10vw;      

    }

    .Fin_uni_images img{
        width: 30vh;
        height: 35vh;  
    }

    .Fin_uni_name1,.Fin_uni_name2,.Fin_uni_name3,.Fin_uni_name4,.Fin_uni_name5,.Fin_uni_name6,.Fin_uni_name7{
        margin-top: 33vh;
        margin-left: 20vw;
        font-size: 16px;
        width: 35vh;
        height: 4vh;
    }

}