.Fin-why-rel-country-container-Finland{
    background-color: rgb(17, 84, 171);
    display: flex;
    height: 50vw;
    align-items: center;
    justify-content:center;
}

.Fin-why-rel-country-container-flag{
    padding: 2% 2% 2% 2%;
    z-index: 0;
}

.Fin-why-rel-country-container-flag img{
    height: 35vw;
    width: 35vw;
    border-radius: 50%;
    border: 2px solid #ffffff;   
}


.rel-text-Fin{
    width: 45vw;
    padding-left: 2vw;
}


.rel-text-Fin p{
    font-size: 16px;
    width: 40vw; 
    color: black;
    line-height: 1.3;
    margin: 2% 4%;
}

.Fin-child1,.Fin-child2,.Fin-child3,.Fin-child4,.Fin-child5 {
    position: relative;
    border-radius: 50px;
    height:  9vh;
    margin: 1vw;
    width: 43vw;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.Fin-child1 {
    background: linear-gradient(90deg, rgba(0, 47, 108, 1) 68%, rgba(255, 255, 255, 1) 100%);    left: -5vw;   
    left: -2.5vw
}

.Fin-child1 {
    color: black !important;
}

.Fin-child1 p{
    color: white;
}

.Fin-child2 {
    background: #012169;    
    left: -1vw;
}

.Fin-child2 p {
    color: white !important;
}

.Fin-child3 {
    background: white;
    left: 0vw;
}

.Fin-child3 p {
    color: #000 !important;
}

.Fin-child4 {
    background: #002F6C;
    left: -1vw ;
}

.Fin-child4 p{
    color: white;
}

.Fin-child5 {
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 47, 108, 1) 39%);    left: -2.5vw;
    left: -2.5vw;
}

.Fin-child5 p {
    color: white;
}


@media only screen and (max-width: 1000px){

    .rel-text-Fin{
        width: 50vw;
    }

    .Fin-child1,.Fin-child2,.Fin-child3,.Fin-child4,.Fin-child5 {
        height: 7vw;
        width: 45vw;
    }

    .rel-text-Fin p{
        font-size: 14px;
        width: 40vw;
    }
}

@media only screen and (min-width:590px) and (max-width: 700px) {
    .Fin-child1,.Fin-child2,.Fin-child3,.Fin-child4,.Fin-child5 {
        height: 7vh;
        width: 40vw;
    }

    .rel-text-Fin p{
        font-size: 0.7rem;
        padding: 1.5vw;
        width: 35vw;
        padding: 0;
        margin: 2% 4%;
    }


}

@media only screen and (max-width:590px) {
    .Fin-why-rel-country-container-Finland{
        flex-direction: column;
        height: fit-content;
    }

    .Fin-why-rel-country-container-flag{
        margin-top: 20px;
    }

    .rel-text-Fin{
        width: 75%;
        padding-left: 0;
    }

    .Fin-why-rel-country-container-flag img{
        height: 55vw;
        width: 55vw;
    }

    .Fin-child1,.Fin-child2,.Fin-child3,.Fin-child4,.Fin-child5 {
        width: 70vw;
        height: 10vh;
        margin: 3vw;
        left: 0;
    }

    .rel-text-Fin p{
        width: 65vw;
        font-size: 16px;
        margin-left: 10px;
    }   
}

@media screen and (max-width:500px) {
    .Fin-why-rel-country-container-Finland{
        flex-direction: column;
        height: fit-content;
    }

    .Fin-child1,.Fin-child2,.Fin-child3,.Fin-child4,.Fin-child5 {
        height: 10vh;
        width: 85vw;
    }
    .Fin-why-rel-country-container-flag img{
        width: 60vw;
        height: 60vw;
    }

    .Fin-child1,.Fin-child2,.Fin-child3,.Fin-child4,.Fin-child5 {
        width: 80vw;
        height: fit-content;
        margin: 3vw;
        left: 0;
    }

    .rel-text-Fin{
        width: 90%;
        padding-left: 0;
    }

    .rel-text-Fin p{
        width: 70vw;
        font-size: 16px;
        padding: 2.5vw;
        margin-left: 10px;
    }
}