* {
    margin: 0;
    padding:0;
    overflow-x: hidden;
    font-family: Amiri;
}


  .img_1{
    max-width: 100vw;
    width: 100vw;
  }

  .title{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#B11212;
    font-size: 60px;
    background-color: #D9D9D9;
    border-radius: 60px;
    padding: 10px 40px;
  }
  
@media only screen and (min-width: 600px) and (max-width:990px){
  .title{
    top: 40%;
    font-size: 2.5rem;
  }

}

@media only screen and (min-width: 450px) and (max-width:600px){
  .title{
    top: 35%;
    font-size: 2rem;
  }
  
}

@media only screen and (min-width: 0px) and (max-width:450px){
  .title{
    top: 55vw;
    font-size: 20px;
  }
  
}

    

