.why-rel-country-container{
    background: #0C5448;
    display: flex;
    height: 50vw;
    align-items: center;
    justify-content:center;
}

.why-rel-country-container-flag{
    padding: 2% 2% 2% 2%;
    z-index: 0;
    
}

.why-rel-country-container-flag img{
    height: 35vw;
    width: 35vw;
    border-radius: 50%;
    border: 2px solid #F3AE27;   
}

.rel-text-USA{
    padding-left: 5%;
    width: fit-content;
}


.rel-text-USA p{
    font-size: 1.1vw;
    width: 30vw; 
    color: white;
    margin: 2%;
    padding: 0 3%;
    line-height: 1.5;
}

.USA-child1,.USA-child2,.USA-child3,.USA-child4,.USA-child5 {
    position: relative;
    border-radius: 50px;
    height:  5vw;
    width: 35vw;
    margin: 1vw;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.USA-child1 {
background: linear-gradient(
  5deg,
  rgba(255, 255, 255, 1) 0%,      /* White */
  rgba(60, 59, 110, 1) 25%,       /* Blue (American flag blue #3c3b6e) */
  rgba(179, 25, 66, 1) 71%,       /* Red (American flag red #b31942) */
  rgba(255, 255, 255, 1) 98%      /* White */
);
    left: -2.5vw;   
}

.USA-child1 p {
    color: white !important;
}

.USA-child2 {
    background: #012169;    
    left: -1vw;
}

.USA-child2 p {
    color: white !important;
}

.USA-child3 {
    background: white;
    left: -0vw;
}

.USA-child3 p {
    color: #000 !important;
}

.USA-child4 {
    background: #E4002B;
    left: -1vw;
}

.USA-child5 {
    background: linear-gradient(
        185deg,
        rgba(255, 255, 255, 1) 0%,      /* White */
        rgba(60, 59, 110, 1) 25%,       /* Blue (American flag blue #3c3b6e) */
        rgba(179, 25, 66, 1) 71%,       /* Red (American flag red #b31942) */
        rgba(255, 255, 255, 1) 98%      /* White */
      );
    left: -2.5vw;

}

.USA-child5 p {
    color: white !important;
}


@media only screen and (max-width: 1000px){
    .USA-child1,.USA-child2,.USA-child3,.USA-child4,.USA-child5 {
        height: 8vh;
        width: 40vw;
    }

    .rel-text-USA p{
        margin: 0 2%;
        font-size: 14px;
        width: 38vw;
        padding: 1%;
    }
}

@media only screen and (min-width:590px) and (max-width: 700px) {
    .USA-child1,.USA-child2,.USA-child3,.USA-child4,.USA-child5 {
        height: 6vh;
        width: 40vw;
    }

    .rel-text-USA p{
        margin: 0 2%;
        font-size: 0.7rem;
        padding: 1%;
        width: 38vw;
    }


}

@media only screen and (max-width:590px) {
    .why-rel-country-container{
        flex-direction: column;
        height: fit-content;
    }

    .why-rel-country-container-flag{
        margin-top: 20px;
    }

    .why-rel-country-container-flag img{
        height: 55vw;
        width: 55vw;
    }

    .USA-child1,.USA-child2,.USA-child3,.USA-child4,.USA-child5 {
        width: 75vw;
        height: 12vw;
        margin: 3vw;
        left: 0;
    }

    .rel-text-USA p{
        width: 70vw;
        font-size: 16px;
        padding: 1.2vw;
        margin-left: 10px;
    }   
}

@media screen and (max-width:500px) {
    .USA-child1,.USA-child2,.USA-child3,.USA-child4,.USA-child5 {
        height: 10vh;
        width: 85vw;
    }
    .why-rel-country-container-flag img{
        width: 60vw;
        height: 60vw;
    }

    .USA-child1,.USA-child2,.USA-child3,.USA-child4,.USA-child5 {
        width: 80vw;
        height: fit-content;
        margin: 3vw;
        left: 0;
    }

    .rel-text-USA p{
        width: 70vw;
        font-size: 16px;
        padding: 2.5vw;
        margin-left: 10px;
    }
}