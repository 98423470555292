  .bg-color-qa{
    background-color: #eff5f8;
  }
  
  
  .container {
    width: 90%;
    margin: 0px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    /* background-color: #880000e6; */
    background-color: rgba(24, 21, 21, 0.606);
    /* background-color: rgba(47, 47, 145, 0.921); */
    font-family: Arial, sans-serif;
  }
  
  .question {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: white;
  }
  
  .typewriter-text {
    font-size: 1.5em;
    white-space: pre-line;
    color: white;
  }
  
  @media screen and (max-width:800px) {
    .container{
      margin: 0px auto;
      width: 80%;
    }

    .question {
      font-size: 1.2em;
    }
    
    .typewriter-text {
      font-size: 1.2em;
    }
  }

  @media screen and (max-width:500px) {
    .container{
      margin: 0px auto;
      width: 80%;
    }

    .question {
      font-size: 1em;
    }
    
    .typewriter-text {
      font-size: 1em;
    }
  }