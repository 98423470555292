.Latvia-why-rel-country-container{
    background: linear-gradient(1deg, rgb(168, 160, 160) 0%, rgb(44, 42, 42) 80%);
    display: flex;
    height: 50vw;
    align-items: center;
    justify-content:center;
}

.Latvia-why-rel-country-container-flag{
    padding: 2% 2% 2% 2%;
}

.Latvia-why-rel-country-container-flag img{
    height: 35vw;
    width: 35vw;
    border-radius: 50%;
    border: 2px solid #faf7f0;   
}

.rel-text-Lat{
    padding-left: 5%;
}


.rel-text-Lat p{
    font-size: 16px;
    width: 25vw;
    color: white;
    padding: 0 1vw;
    margin: 10px 0;
    border-radius: 10px; 
    margin-bottom: 20px;
}

.Lat-child1,.Lat-child2,.Lat-child3,.Lat-child4,.Lat-child5 {
    position: relative;
    border-radius: 50px;
    margin: 2vw;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.Lat-child1 {
    background:  #b11212;    
    left: -5vw;       
}

.Lat-child2 {
    background: black;    
    left: -2.5vw;
}

.Lat-child3 {
    background: #b11212;
    left: -1vw;
}

.Lat-child4 {
    background: #131312;;
    left: -2.5vw;
}

.Lat-child5 {
    background:  #b11212;    
    left: -5vw;
}

@media only screen and (max-width: 1000px){
    .Lat-child1,.Lat-child2,.Lat-child3,.Lat-child4,.Lat-child5 {
        height: 6vw;
        width: 40vw;
    }

    .rel-text-Lat p{
        font-size: 0.8rem;
        width: 35vw;
        margin: 8px 0;
    }
}

@media only screen and (min-width:590px) and (max-width: 700px) {
    .Latvia-why-rel-country-container{
        flex-direction: row;
        height: fit-content;
    }

    .Lat-child1,.Lat-child2,.Lat-child3,.Lat-child4,.Lat-child5 {
        height: 6vh;
    }

    .rel-text-Lat p{
        font-size: 0.7rem;
        padding: 1.5vw;
        margin: 4px 6px;
        padding: 2px;
    }


}

@media only screen and (max-width:590px) {
    .Latvia-why-rel-country-container{
        flex-direction: column;
        height: fit-content;
    }

    .Latvia-why-rel-country-container-flag{
        margin-top: 20px;
    }

    .Latvia-why-rel-country-container-flag img{
        height: 55vw;
        width: 55vw;
    }

    .Lat-child1,.Lat-child2,.Lat-child3,.Lat-child4,.Lat-child5 {
        width: 75vw;
        height: 8vh;
        left: 0;
    }

    .rel-text-Lat p{
        width: 65vw;
        font-size: 1rem;
        padding: 0 15px;
    }
    
}

@media screen and (max-width:450px) {
    .Lat-child1,.Lat-child2,.Lat-child3,.Lat-child4,.Lat-child5 {
        height: fit-content;
        width: 85vw;
    }
    .Latvia-why-rel-country-container-flag img{
        width: 60vw;
        height: 60vw;
    }
    .rel-text-Lat p{
        width: 65vw;
        font-size: 1rem;
        padding: 0 15px;
        margin: 5px 0;
    }
}