.r-my-card {
    flex: 1 0 25%; /* Ensures exactly 4 cards are shown */
    height:420px;
    border-radius: 20px;
    margin: 10px;
    color: white;
    background: rgb(42,86,70);
    background: linear-gradient(90deg, rgba(42,86,70,1) 13%, rgba(65,31,31,1) 71%);
    overflow: hidden;
    padding: 5px;
  }
  
  .r-my-card-rating{
    color:goldenrod;
    padding:2%;
    gap:10px;
    size: 5px;
    text-align: center;
  }

  .r-my-card-image{
    width: 100%;
    height: 11vw;
    text-align: center;
  }

  .r-my-card-image img{
    width: 10vw;
    height: 10vw;
    border-radius: 100%;

  }
  
  .r-my-card-text{
    padding:4%;
    font-size:1.25rem;
    letter-spacing: 1.5px;
    line-height: 1.25;
    margin-bottom: 2%;
  }

  .r-my-card-name{
    font-size: 1rem;
    font-weight: 100;
    color: rgb(255, 255, 255); 
    text-align: center; 
    margin-left:auto;
    font-weight: 100;
  }
  
  .r-my-card-star{
    font-size:25px;
    padding:4% 2%;
  }
  
  
  
  @media (max-width: 1024px) {
  
    .r-my-card {
        min-width: 28vw;
        max-width: 28vw;
        height: 330px;
    }
    .r-my-card-text{
      font-size:1rem;
    }
  
    
    
  }
  
  
  @media (max-width: 768px) {
  
    .r-my-card {
        min-width: 35vw;
        max-width: 35vw;
    }

  }
  
  
  @media (max-width: 600px) {
  
    .r-my-card {
        min-width: 80vw;
        max-width: 80vw;
        height: 330px;
    }
    .r-my-card-image{
      width: 100%;
      height: 25vw;
      text-align: center;
    }
  
    .r-my-card-image img{
      width: 23vw;
      height: 23vw;
      border-radius: 100%;
  
    }
    
   }