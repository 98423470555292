.services{
    color: black;
    height: fit-content;
    display: flex;
    flex-direction: column;
}

.service-main {
    position: relative;
}

.service-main img {
    display: block;
    width: 100%; 
    height: 100vh;
}

.services_title {
    position: absolute;
    white-space: nowrap;
    top: 45%; 
    left: 35vw; 
    /* transform: translate(-0%,-0%); */
    color: white; 
    font-size: 5rem; 
    text-shadow: lightblue 1px 0 10px;
    margin: 0; 
}

.services h2{
    font-size: 2.5rem;
    color: #0c5448;
    align-self: center;
    margin-top: 2vw;
    text-shadow: lightseagreen 1px 0 5px;  
}

.all_services{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 90%;
    align-self: center;
    margin-top: 5vw;
}

.service_image{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    z-index: 1;
    margin: auto 0;
    padding-bottom:5vw;
    margin: 0px 1vw;
}

.service_image img{
    width: 25vw;
    height: 20vw;
    background-size: 100%;
    border-radius: 5%;
}

.service_name {
    white-space: nowrap;
    width: 23vw;
    height: 2.5vw;
    color:white;
    position: absolute;
    margin-top: 19vw;
    margin-left: 8vw;
    font-size: 1.5vw;
    border-radius: 20px;
    padding: 3px 1.5vw;
    background-color: #aa336bda;
}

@media only screen and (max-width:800px) {

    
    .service-main img {
        height: 70vh;
    }

    .services_title {
        top: 45%; 
        left: 30vw; 
        font-size: 4rem; 
    }

    .all_services{
        display:flex;
        flex-wrap:wrap;     
        width: 90%;   
    }
    
    .services h2{
        font-size: 2rem;
    }
    
    .service_image img{
        width: 35vw;
        height: 30vw;
        background-size: 100%;
        border-radius: 5%;
    }
    .service_name {
        width: 28vw;
        height: 3vh;
        margin-top: 29vw;
        margin-left: 12vw;
        font-size: 16px;
    }    
}   

@media only screen and (max-width:550px) {

      
    .service-main img {
        height: 50vh;
    }

    .services_title {
        top: 45%; 
        left: 30vw; 
        font-size: 2rem; 
    }

    .all_services{
        display:flex;
        flex-wrap:wrap;     
        width: 80%;   
    } 
        
    .services h2{
        font-size: 3.5vh;
        white-space:nowrap;
    }
    
    .service_image {
        padding-bottom: 10vw;
    }
    .service_image img{
        width: 60vw;
        height: 45vw;
        background-size: 100%;
        border-radius: 5%;
    }
    .service_name {
        width: 33vh;
        height: 3vh;
        margin-top: 43vw;
        margin-left: 20vw;
    }
    
}