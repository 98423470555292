.why-rel-country-container{
    background: linear-gradient(1deg, rgb(168, 160, 160) 0%, rgb(223, 132, 107) 80%);
    display: flex;
    height: 50vw;
    align-items: center;
    justify-content:center;
}

.why-rel-country-container-flag{
    padding: 2% 2% 2% 2%;
}

.why-rel-country-container-flag img{
    height: 35vw;
    width: 35vw;
    border-radius: 50%;
    border: 2px solid #faf7f0;   
}

.rel-text-Aus{
    padding-left: 5%;
}


.rel-text-Aus p{
    font-size: 16px;
    width: 35vw;
    color: white;
    padding: 0.5vw 1vw; 
    border-radius: 10px; 
}

.Australia-child1,.Australia-child2,.Australia-child3,.Australia-child4,.Australia-child5 {
    position: relative;
    border-radius: 50px;
    height:  5vw;
    width: 40vw;
    margin: 1vw;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.Australia-child1 {
    background: linear-gradient(5deg, rgba(255, 255, 255, 1) 0%, rgba(12, 55, 152, 1) 25%, rgba(1, 33, 105, 1) 71%, rgba(228, 0, 43, 1) 98%);
    left: -2.5vw;   
}

.Australia-child1 p {
    color: white !important;
}

.Australia-child2 {
    background: #012169;    
    left: -1vw;
}

.Australia-child2 p {
    color: white !important;
}

.Australia-child3 {
    background: white;
    left: 0vw;
}

.Australia-child3 p {
    color: #000 !important;
}

.Australia-child4 {
    background: #E4002B;
    left: -1vw;
}

.Australia-child5 {
    background: linear-gradient(5deg, rgba(228, 0, 43, 1) 2%, rgba(1, 33, 105, 1) 29%, rgba(12, 55, 152, 1) 75%, rgba(255, 255, 255, 1) 100%);
    left: -2.5vw;

}

.Australia-child5 p {
    color: white !important;
}

@media only screen and (max-width: 1000px){
    .Australia-child1,.Australia-child2,.Australia-child3,.Australia-child4,.Australia-child5 {
        height: 7.2vw;
        width: 47vw;
    }

    .rel-text-Aus p{
        padding: 1vw 1vw; 
        font-size: 15px;
        width: 45vw;
    }
}

@media only screen and (max-width: 700px) {
    .why-rel-country-container{
        flex-direction: column;
        height: fit-content;
    }

    .Australia-child1,.Australia-child2,.Australia-child3,.Australia-child4,.Australia-child5 {
        height: 9vh;
        width: 60vw;
        padding: 0;
        margin: 2vw;
        left: 0;
    }


    .rel-text-Aus p{
        font-size: 15px;
        width: 55vw;
        margin: 5px 15px;
        padding: 5px;
    }


}

@media only screen and (max-width:590px) {
    .why-rel-country-container{
        flex-direction: column;
        height: fit-content;
    }

    .why-rel-country-container-flag{
        margin-top: 20px;
    }

    .why-rel-country-container-flag img{
        height: 55vw;
        width: 55vw;
    }

    .Australia-child1,.Australia-child2,.Australia-child3,.Australia-child4,.Australia-child5 {
        width: 75vw;
        height: fit-content;
        left: 0;
    }

    .rel-text-Aus p{
        width: 65vw;
        font-size: 1rem;
        padding:15px;
    }
    
}

@media screen and (max-width:450px) {
    .Australia-child1,.Australia-child2,.Australia-child3,.Australia-child4,.Australia-child5 {
        height: fit-content;
        width: 85vw;
    }
    .why-rel-country-container-flag img{
        width: 60vw;
        height: 60vw;
    }

    .rel-text-Aus p{
        width: 75vw;
        margin: 0;
    }
}