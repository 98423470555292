.navbar_header {
  top: 0;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content:flex-end;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.661);
  box-shadow: 0px 4px 4px 0px #00000026;
  z-index: 1000;
  /* padding: 10px 0px 10px 0px; */
  gap: 30vw;
}

/* Navbar Logo Styling */
.navbar_logo {
  width: 300px;
  margin-left: 1rem;
}

.navbar_logo img {
  width: 110px;
  height: 80px;
}

/* Navigation Menu Styling */
nav {
  width: 100%;
  margin-right: 40px;
}

.menu {
  list-style-type: none;
  display: flex;
  align-items: center;
  gap: 2vw;
  padding: 0vw;
}




/* Menu Links Styling */
.menu .navbar_li {
  text-decoration: none;
  align-items: center;
  padding:  1vw ;
}

.menu .navbar_li .anchor_a {
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: 450;
  padding: 8px;
  white-space: nowrap;
  transition: background-color 0.3s ease;
}

.menu .navbar_li .anchor_a:hover {
  background-color: #0c5448;
  border-radius: 5px;
}

/* Toggle Button Styling */
.menu-toggle {
  display: none;
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: white;
  margin-bottom: 5px;
  margin-right: 30px;
}

/* Social Icons Styling */
.social-icons {
  position: fixed;
  bottom: 2rem;
  right: 20px;
  z-index: 5;
  display: flex;
  flex-direction: column;
}

.social-icons img {
  width: 70px;
  height: 70px;
  margin: 10px 0;
}

@media screen and (max-width: 1000px) {
  .navbar_header{
    gap: 20%;
  }
}

/* Responsive Styling */
@media screen and (max-width: 800px) {
  nav{
    margin-right: 0;
    align-items: center;
    align-self: center;
    justify-content: center;
  }
  .navbar_header{
    gap: 0;
    flex-direction: column;
    align-items:flex-start;
  }

  .navbar_logo {
    position:relative;
  }
  
  .navbar_logo img {
    width: 90px;
    height: auto;
  }
  
  /* Display the toggle button for smaller screens */
  .menu-toggle {
    display: block;
    position:absolute;
    right: 0px;
    margin: 10px 0px 10px 5px;
    top: 20px;
    z-index: 1001;
  }

  /* Hide the menu by default on smaller screens */
  .menu {
    display: none;
    flex-direction: column;
    position: relative;
    /* top: 60px; */
    left:0;
    width: 100%;
    background-color: #292626;
    font-size: 24px;
    z-index: 999;
    gap: 0;
    padding: 0;
  }

  /* Show the menu when the 'show' class is added */
  .menu.show {
    display: flex;
    width:100%;
    margin-left: auto;
  }

  /* Menu Item Styling on Mobile */
  .menu .navbar_li {
    width: 80%;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid #555;
  }
  .menu .navbar_li:last-child{
    border-bottom: none;
  }

  /* Social Icons Positioning on Mobile */
  .social-icons {
    position: fixed;
    bottom: 1rem;
    right: 10px;
    z-index: 5;
  }
}