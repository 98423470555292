.pop_universities{
    background: linear-gradient(180deg, rgb(227, 244, 241) 0%, rgba(12, 84, 72, 1) 100%);;
    height: fit-content;
    z-index: 0;
    position: relative;
    padding-bottom: 3vh;
}

.pop_universities h1{
    margin-top: 0;
    font-size: 3rem;
    text-align: center;
    padding-top: 3vw;
    color: rgba(177, 18, 18, 80%);
    text-shadow: 0 0 3px #fdfdfd;
}

.pop_uni_images {
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    z-index: 1;
    padding-bottom:5vw;
}

.werty{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    margin:  5vw 10vw 0 10vw;
    width: 80%;
}

.pop_uni_images img{
    width: 18vw;
    height: 23vw;
    overflow: hidden;
    background-size: 100%;
    border-radius: 1vw;
    margin: 0px 2vw;
}

.pop_uni_name1,.pop_uni_name2,.pop_uni_name3,.pop_uni_name4,.pop_uni_name5,.pop_uni_name6,.pop_uni_name7,.pop_uni_name8,.pop_uni_name9{
    white-space: nowrap;
    width: 19vw;
    height: 3vh;
    color:white;
    position: absolute;
    margin-top: 22vw;
    margin-left: 10vw;
    font-size: 1.3vw;
    border-radius: 20px;
    padding: 0.25vw 1.5vw ;
}

.pop_uni_name1{
    background-color: rgba(177, 18, 18, 80%);
}

.pop_uni_name2{
    background-color: rgba(0, 0, 0, 50%);
}

.pop_uni_name3{
    background-color: rgba(177, 18, 18, 80%);
}

.pop_uni_name4{
    background-color: rgba(0, 0, 0, 50%);
}

.pop_uni_name5{
    background-color: rgba(177, 18, 18, 80%);
}

.pop_uni_name6{
    background-color: rgba(0, 0, 0, 50%);
}

.pop_uni_name7{
    background-color: rgba(177, 18, 18, 80%);
}

.pop_uni_name8{
    background-color: rgba(0, 0, 0, 50%);
}

.pop_uni_name9{
    background-color: rgba(177, 18, 18, 80%);
}

@media only screen and (min-width:550px) and (max-width:750px) {

    .pop_uni_images img{
        width: 25vw;
        height: 30vw;        
    }

    .pop_uni_name1,.pop_uni_name2,.pop_uni_name3,.pop_uni_name4,.pop_uni_name5,.pop_uni_name6,.pop_uni_name7,.pop_uni_name8,.pop_uni_name9{
        margin-top: 29vw;
        font-size: 2.2vw;
        width: 35vw;
        height: 3.5vh;
        margin-left: 15vw;
    }

    .pop_universities h1{
        font-size: 2rem;
    }
}

@media only screen and (min-width:0px) and (max-width:550px) {
    
    .pop_universities h1{
        font-size: 1.8rem;
        padding-top: 2vh;
    }

    .pop_uni_images{
        padding-bottom: 10vw;
    }

    .pop_uni_images img{
        width: 30vh;
        height: 35vh;        
    }

    .pop_uni_name1,.pop_uni_name2,.pop_uni_name3,.pop_uni_name4,.pop_uni_name5,.pop_uni_name6,.pop_uni_name7,.pop_uni_name8,.pop_uni_name9{
        margin-top: 34vh;
        margin-left: 20vw;
        font-size: 1rem;
        width: 35vh;
        height: 4vh;
    }

}