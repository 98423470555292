.our-vision-full{
    width: 100%;
    background-color: #EFF5F8;
  }
  
  .title-our-vision{
    padding: 2vw 2vw 1vw 2vw;
    margin: 15px 10px;
  }
  
  .title-our-vision h2{
    font-size: 3rem;
    color: #0c5448;
  }
  
  .our-vision-container{
    display: flex;
    justify-content: left;
    width: 90%;
    margin: 0% auto;    
  }
  
  .our-vision-description {
    margin: 2vw;
    align-items: center;
  }

  .our-vision-image{
    flex: 0.53;
    z-index: 1;
    left: 3vw;
  }
  
  .our-vision-image img{
    margin-left: 5vw;
    width: 85%;
    border:2px solid #0c5448;
  }
  
  .our-vision-content{
    position: absolute;
    /* padding: 1vw 2vw; */
    background-color: #F4F7FC; 
    margin: 4vw auto;
    border: 2px solid #F3AE27;
    z-index: 2;
    left: 45vw;
    height: 360px;
    width: 600px;
    font-size: 20px;
    line-height: 1.3; 
    align-items: center;
  }
  

  @media only screen and (min-width: 1020px) and (max-width:1200px) {
    .our-vision-image{
      flex: 0.62;
      left: 5vw;
    }
    .our-vision-image img{
      margin-left: 0vw;
      width: 85%;
      border:2px solid #0c5448;
    }
  
    .our-vision-content{
      margin: 4vw 3vw;
      height: 360px;
      width: 550px;
      left: 38vw;
      font-size: 20px;
    }

  }
  
  @media only screen and (min-width: 950px) and (max-width:1020px) {
    .our-vision-image{
      flex: 0.6;
      left: 5vw;
    }
  
    .our-vision-content{
      margin: 6vw 3vw;
      height: 28vw;
      width: 50vw;
      left: 40vw;
      font-size: 1rem;
    }
  
  }
  @media only screen and (min-width: 775px) and (max-width:950px) {
    .our-vision-image{
      flex: 0.5;
      left: 5vw;
    }
  
    .our-vision-content{
      margin: 3vw 2vw;
      padding: 1.5vw;
      height: 25vw;
      width: 57vw;
      left: 35vw;
      font-size: 16px;
      line-height: 1.4;
    }

    .our-vision-content::-webkit-scrollbar {
      width: 5px; 
      background-color: #f0f0f0; 
    }
    
    .our-vision-content::-webkit-scrollbar-thumb {
      background-color: #0c5448; 
      border-radius:5px;
    }
  }
  
  @media only screen and (min-width: 0px) and (max-width:775px) {
  
    .our-vision-full{
      background-color: white;
      justify-content: center;
      width: 100%;
      z-index: 1 !important;
    }
  
    .our-vision-image{
      left: 5vw;
      width: auto;
      margin: auto;
      justify-content: center;
    }
    .our-vision-image img{
      margin-left: 0vw;
      width: 99%;
      border:2px solid #0c5448;
    }
    .our-vision-container{
      display: flex;
      flex-direction: column;
      z-index: 1 !important;
      
    }  
  
    .our-vision-image .our-vision-content{
      z-index: 1 !important;
    }
  
    .our-vision-content{
      position: relative !important;
      width: auto;
      left: 0;
      overflow: visible;
      height: fit-content;
      border: 0;
      background-color: white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
  
    .title-our-vision h2{
      font-size: 2.5rem;
      color: #0c5448;
    }
  
    .title-our-vision{
      padding: 2vw 5vw;
      text-align: center;
    }

    .our-vision-description{
      font-size: 18px;
    }
  }
  
  @media only screen and (min-width: 0px) and (max-width:480px) {
    
    .our-vision-container{
      padding: 0px;
      margin: 0;
      width: 100%;
    }

    .our-vision-image{
      justify-content: center; /* Centers horizontally */
      align-items: center; /* Centers vertically */
      text-align: center; /* Optional: aligns the text centrally within its container */
      width: 80%;
    }
  
    .our-vision-image img{
      width: 100%;
      height: 60vw;
    }
  
    .our-vision-content{
      position: relative !important;
      margin: 15px 20px;
    }
  
    .title-our-vision h2{
      font-size: 2rem;
      color: #0c5448;
    }
  
    .title-our-vision{
      padding: 2vw 6vw;
      text-align: center;
    }

    .our-vision-description{
      font-size: 16px;
    }
    
  
  }
  