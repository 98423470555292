.full{
  width: 100%;
  background-color: #EFF5F8;
}

.title-why-we-best{
  padding: 2vw 2vw 1vw 2vw;
}

.title-why-we-best h2{
  font-size: 3rem;
  color: #0c5448;
}

.best-choice-container{
  display: flex;
  justify-content: left;
  width: 90%;
  margin: 2% auto;
}

.best-choice-description {
  margin: 0px;
}

.best-choice-footer {
  margin: 0px;
}

.best-choice-image{
  flex: 0.5;
  z-index: 1;
  left: 5vw;
}

.best-choice-image img{
  width: 100%;
}

.best-choice-content{
  position: absolute;
  padding: 2vw;
  background-color: #F4F7FC; 
  margin: 4vw 3vw;
  border: 2px solid #F3AE27;
  z-index: 2;
  font-size: 20px;
  font-size: clamp(0.5rem, 20px, 3rem);
  line-height: 1.3;
  height: 400px;
  width: 650px;
  left: 38vw;
}

.best-choice-list li{
  color: black;
  font-size: 20px;
  list-style-type: disc;
  margin-left: 20px;
  padding: 3px 2px; 
}

.best-choice-list {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
  text-align: start;
  color: black;
}


@media only screen and (min-width: 1100px) and (max-width:1300px) {
  .best-choice-image{
    flex: 0.5;
    left: 5vw;
  }

  .best-choice-content{
    margin: 3vw;
    height: 320px;
    width: 580px;
    left: 38vw;
  }

  .best-choice-content, .best-choice-footer{
    font-size: 1.15rem;

  }
  
  .best-choice-list li{
    font-size: 1.1rem;
    padding: 2px;
    margin-left: 0px;

  } 
  .best-choice-list{
    margin-bottom: 5px;

  }

}

@media only screen and (min-width: 950px) and (max-width:1100px) {
  .best-choice-image{
    flex: 0.6;
    left: 5vw;
  }

  .best-choice-content{
    margin: 3.5vw 3vw;
    height: 350px;
    width: 480px;
    left: 40vw;
  }

  .best-choice-content, .best-choice-footer{
    font-size: 1rem
  }
  
  .best-choice-list li{
    font-size: 1rem;
    margin-left: 0px;

  }
  
  .best-choice-list {
    margin: 2px 0;
  }

}
@media only screen and (min-width: 775px) and (max-width:950px) {
  .best-choice-image{
    flex: 0.5;
    left: 5vw;
  }

  .best-choice-content{
    margin: 2vw;
    padding: 1.5vw;
    height: 32vw;
    width: 55vw;
    left: 35vw;
    font-size: 14px;
    line-height: 1.4;
  }

  .best-choice-list{
    margin: 0px;
  }

  .best-choice-content::-webkit-scrollbar {
    width: 5px; 
    background-color: #f0f0f0; 
  }
  
  .best-choice-content::-webkit-scrollbar-thumb {
    background-color: #0c5448; 
    border-radius:5px;
  }

  .best-choice-list li{
    font-size: 14px;
    padding: 0px;
    margin-left: 0px;
  }
  
  .best-choice-footer {
    font-size: 14px;
  }
}

@media only screen and (min-width: 0px) and (max-width:775px) {

  .full{
    background-color: white;
    justify-content: center;
    width: 100%;
    z-index: 1 !important;

  }

  .best-choice-image{
    flex: 0;
    left: 5vw;
    width: auto;
    margin: auto;
  }
  
  .best-choice-image img{
    width: 100%;
    height: 45vh;
  }

  .best-choice-container{
    display: flex;
    flex-direction: column;
    z-index: 1 !important;
    
  }  

  .best-choice-image .best-choice-content{
    z-index: 1 !important;
  }

  .best-choice-content{
    position: relative !important;
    width: auto;
    left: 0;
    overflow: visible;
    height: fit-content;
    border: 0;
    background-color: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .title-why-we-best h2{
    font-size: 2.5rem;
    color: #0c5448;
  }

  .title-why-we-best{
    padding: 2vw 5vw;
    text-align: center;
  }
}

@media only screen and (min-width: 0px) and (max-width:480px) {
  
  .best-choice-container{
    padding: 0px;
  }

  .best-choice-image img{
    width: 100%;
    height: 33vh;
  }

  .best-choice-content{
    position: relative !important;
    margin: 15px 5px;
    font-size: 16px;
  }

  .title-why-we-best h2{
    font-size: 2rem;
    color: #0c5448;
  }

  .title-why-we-best{
    padding: 2vw 6vw;
    text-align: center;
  }

  .best-choice-list{
    padding-left: 0px;
  }
  
  .best-choice-list li{
    font-size: 16px;
    padding: 0px;
    margin-left: 0px;
  }

}
