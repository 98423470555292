.contact_div {
  width: 100%;
  height: 100vh;
  object-fit: contain;
  background-position:center;
  background-image: url("../../assests/Contact_2.png");
}

.contact_form {
  position: relative;
  background-color: rgba(115, 115, 115, 0.5);
  width: 35vw;
  height:fit-content;
  padding-bottom: 2vw;
  top: 25%;
  left: 10%;
}

.contact_form h1 {
  color: rgb(255, 255, 255);
  margin-top: 2vw;
  font-size: 2rem;
  margin-left: 9%;
}

.form {
  width:85%;
  margin:auto;
}

.contact_form input {
  /* padding:2%; */
  margin: 3%;
  color: Black;
  background-color: white;
  border-radius: 12px;
  border: none;
  font-size: 1.5vh;
  text-align: left;
}

.contact_form .input_name {
  width: 42%;
  padding: 3%;
}
.contact_form .input_email,
.contact_form .input_phone{
  width: 70%;
  padding: 3%;
}

.contact_form .input_comment {
  border-radius: 10px;
  margin: 3%;
  border: none;
  font-size: 2vh;
  width: 80%;
  padding: 3%;
}

.contact_form .Contact-submit {
  padding: 2.5%;
  background-color: rgba(255, 0, 0, 0.8);
  align-items: center;
  color: white;
  margin-top: 1vw;
  margin-left: 15%;
  width: 15vw;
  border: none;
  font-weight: bold;
  font-size: 1.75vh;
  border-radius: 10px;
  overflow: hidden;
}

.responsive-map-container {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.responsive-map-container iframe,
.responsive-map-container object,
.responsive-map-container embed {
  position: absolute;
  top: 100px;
  left: 15%;
  width: 70%;
  height: 70%;
}

@media screen and (max-width: 900px) {
  .contact_div {
    background: url("../../assests/Contact_2.png");
    height: 95vh;
    background-position:-110ch;
  }

  .contact_form {
    top: 25%;
    left: 18%;
    width: 60%;
    height: fit-content;
    padding-bottom: 5vw;
  }

  .contact_form .Contact-submit {
    width: 20vw;
  }

  .contact_form h1 {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {

  .contact_div {
    background: url("../../assests/Contact_2.png");
    height: 85vh;
    background-position:-130ch;
  }

  .contact_form {
    top: 15%;
    left: 15%;
    width: 70%;
    height: 60vh;
    padding-bottom: 5vw;
  }

  .contact_form .Contact-submit {
    width: 20vw;
  }

  .contact_form h1 {
    padding: 0;
    margin: 5px 0 5px 0;
    left: 0;
    font-size: 30px;
  }

}

@media screen and (max-width: 450px) {

  .contact_div {
    background: url("../../assests/Contact_2.png");
    height: 70vh;
    background-position:-140ch;
  }

  .contact_form {
    top: 15%;
    left: 15%;
    width: 70%;
    height: fit-content;
    padding-bottom: 5vw;
  }

}