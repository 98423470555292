.my-card {
    flex: 1 0 24%; /* Ensures exactly 4 cards are shown */
    width: 85%;
    height: 400px;
    background-color: black;
    margin: 10px;
    color: white;
    background: rgb(12,84,72);
    overflow: hidden; 
  }

  .my-card img{
    width: 100%; /* Ensures the image takes full width of the container */
   
    transition: transform 0.3s ease-in-out;
  }
  
  .my-card img:hover {
    transform: scale(1.1); /* Enlarge the image by 10% on hover */
    cursor: pointer;
  }
  
  .each-card{
    text-decoration: none;
  }
  
  .card-image {
    width: 100%;
    height: auto;
    max-height: 85%; 
    object-fit: cover;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .country-name {
    font-size: 20px;
    font-weight: bold;
    color: gold; 
    text-align: center; 
  }
  
  
  @media screen and (max-width: 1030px) {
  
    .my-card {
        min-width: 28vw;
        max-width: 28vw;
    }
  
    .card-image {
        width: 100%;
        height: 95%; 
        margin-bottom: 10px;
    }
    
  }
  
  
  @media screen and (max-width: 775px) {
  
    .my-card {
        min-width: 30vw;
        max-width: 30vw;
        height: 350px;
    }
  
    .card-image {
        width: 100%;
        height: 85%; 
        margin-bottom: 10px;
    }
    
  }
  
  
  @media screen and (max-width: 600px) {
  
    .my-card {
        min-width: 82vw;
        max-width: 82vw;
        padding: 0;
    }
  
    .country-name {
        font-size: 0.875rem; /* Smaller font size for mobile */
    }
  
    .card-image {
        width: 100%;
        height: 95%; 
        /* margin-bottom: 10px; */
    }
    
  }